/* You can add global styles to this file, and also import other style files */

.bg {
    /* background */
    /* background-image: url('./assets/icons/background.jpg'); */
    //background-color: rgba(218, 218, 218, 0.767);
    //background-image: linear-gradient(#333, #ffffff);
    background-color: #333;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /*dadada*/
    height: 90%;
    //background-image: url('./assets/Images/background.gif');
    background-size: cover;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.backgroundSnackbar {
    background: #333;
    color: white;
    z-index: 9999;
    position: fixed;
    bottom: 8px;
    right: 0;
    /* split text on lines where is \n */
    white-space: pre-wrap;
    min-width: 200px !important;
    max-width: 200px !important;
}
/* 
::ng-deep .mat-snack-bar-container {
    min-width: 50px !important;
    max-width: 50px !important;
  } */
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.whiteRectangle {
    background-color: #ffffff;
    border-radius: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 12px 12px 24px black;
}

.mt-50px {
    margin-top: 50px;
}

.mt-0 {
    margin-top: 0;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -9999;
}

.box200px {
    position: absolute;
    height: 200px;
    width: 200px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 12px 12px 24px black;
    display: table-cell;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.box350px {
    position: absolute;
    height: 350px;
    width: 350px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 12px 12px 24px black;
    display: table-cell;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.box250px {
    position: absolute;
    height: 250px;
    width: 250px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 12px 12px 24px black;
    display: table-cell;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.lineHeight {
    line-height: 30px;
}

.standartFont {
    font-size: 22px;
}

.justyfive {
    text-align: justify;
}

video::-webkit-media-controls {
    display: none !important;
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

h1.hiddenSEO {
    content-visibility: auto;
    margin-top: -99999px;
    color: transparent;
    position: relative;
    z-index: -9999;
    font-size: 12px;
}

.bold {
    font-weight: 900;
 }

 .visibility-auto{
    content-visibility: auto;
 }

 .mr-100{
    margin-right: 100px;
 }

 .w100p{
    width: 100%;
 }